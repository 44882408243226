// Customizable Area Start
import React from "react";
import CommentListCPController, {
    Props,
} from "./CommentListCPController.web";
import { Box, Typography, IconButton, Hidden } from "@material-ui/core";
import { Row } from "antd";
import Search from '../Common/Search.web'
import CommentChatDialog from "./CommentChatDialog.web";
import MediaCommentCP from "../Common/MediaCommentCP.web";
import MediaTypeSelector from "../Common/MediaTypeSelector.web";
import MediaProfileDialogCP from "./MediaProfileDialogCP.web";
import { Message_Icon_Blue } from "../../CustomComponent/SharePlaylistPopup/assets";
import { nxzloader } from "../../assets";
import MessageLinkPopup from '../../../../../components/src/CustomMessage/MessageLinkPopup/MessageLinkPopup.web';
import IconMenu from "components/src/CustomChart/PopUps/IconMenu.web";
import { Menu as MenuIcon } from "@material-ui/icons";
import ListofNewChat from "../../../../Sms/src/ListofNewChat";
import HomeHeaderDropdown from "components/src/CustomChart/PopUps/HomeHeaderDropdown.web";
// Customizable Area End

// Customizable Area Start

// Customizable Area End
export class CommentListCP extends CommentListCPController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        return (
            <Box className="CommentListCpmainbox">

                <MediaTypeSelector selectedPage={this.state.selectedTab} selectPage={this.handlePageChange} />
                <Box className="Commentlist_cp_inside">
                    <Box style={{}} className="hide_in_desktop_menuicon">
                        <IconButton
                            style={{
                                color: "white",
                                padding: '5px'
                            }}
                        >
                            <MenuIcon className="menu-icon" style={{ width: '3rem', height: '2.5rem' }} onClick={this.handleMenu} />
                        </IconButton>
                        <IconMenu anchorEl={this.state.anchorEl} handleClose={this.handleClose} />
                    </Box>
                    <Box style={{ width: '80%' }}>
                        <Search onSearchChange={(value: any) => { this.searchComment(value) }} placeholder="Search Tracks" style={{ width: '100%' }} />
                    </Box>
                    <img src={Message_Icon_Blue} className="commentlist_cp_message_box" data-test-id="open_message_id" onClick={() => {
                        this.openNewMessage()
                    }
                    } />
                    <Hidden only={["lg", 'md', 'xl', 'sm']}>
                        <Box><HomeHeaderDropdown comments={true} /></Box>
                    </Hidden>

                </Box>
                {this.state.loading ? (
                    <Row className="comment_list_cp_loaderbox">
                        <img src={nxzloader} style={{ width: "10%" }} />
                    </Row>
                ) : (
                    <>
                        <Box className="RepliesBox"><Typography className="RepliesText"><span className="ReplyNoText">{this.state.tracks.length}</span> Comments</Typography></Box>
                        <Box style={{ height: '100%', flexGrow: 1, marginBottom: '25px' }} className="CommentContainerScroll">
                            {
                                this.state.tracks.length > 0 ?
                                    this.state.tracks.map((track: any, index: any) => {
                                        return (
                                            <React.Fragment key={track.id} >
                                                <MediaCommentCP 
                                                media={track} 
                                                type={track.attributes.track_upload_type} 
                                                selected={false} 
                                                unread={track?.comment_is_read || this.getReadStatus(track)} 
                                                isVideo={track.attributes.track_upload_type === 'Video'} 
                                                onUserCommentClick={this.viewTrackComments} 
                                                onUserProfileClick={this.viewTrackDetail} 
                                                TrackIndex={index} 
                                                setTrackIndex={(index: any) => { this.setState({ selectedTrackIndex: index }) }} 
                                                selectedTrackIndex={this.state.selectedTrackIndex} 
                                                selectedTab={this.state.selectedTab}
                                                selectedTrack={this.state.selectedTrack}
                                                />
                                            </React.Fragment>
                                        )
                                    })
                                    :
                                    (
                                        <>
                                            <div style={{ height: '60vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                <div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}> No Comments Available </div>
                                            </div>
                                        </>
                                    )
                            }
                        </Box>
                    </>)}
                <ListofNewChat showMsgModal={this.state.isShowMsgModal} closeMsgModal={this.closeMsgModal} closeModalDialog={this.closeMsgModal} />

                <CommentChatDialog
                    isOpen={this.state.showCommentChatDialog}
                    closeCommentChat={this.closeCommentChatDialog}
                    commentMedia={this.state.selectedMedia}
                    selectedComment={this.state.selectedComment}
                    replies={[]}
                    sendComment={this.sendComment}
                    selectedComentReply={this.selectedComentReply}
                    selectedCommentForReply={this.state.selectedCommentForReply}
                    closeCommentReply={this.closeCommentReply}
                    newcomment={this.state.newComment}
                    sendNewComment={this.sendNewComment}
                    setOnChangeNewComment={this.setOnChangeNewComment}
                    handleBlockUser={(id: number, isOpen: boolean) => this.props.handleBlockUser(id, isOpen)}
                    deleteCommants={(id: number, isOpen: boolean) =>  this.props.deleteCommants(id, isOpen)}
                />
                <MediaProfileDialogCP
                    isOpen={this.state.showMediaDetailDialog}
                    closeMediaDialog={this.closeProfileMedia}
                    media={this.state.selectedMedia}
                    selectedTrackMediaDetails={this.state.selectedTrackMediaDetails}
                    selectedTrackStatsDetails={this.state.selectedTrackStatsDetails}
                    handleAddNewUser={this.props.handleAddNewUser}
                    setTrackIndex={this.props.setTrackIndex}
                    isShowMsgModal={this.props.isShowMsgModal}
                    closeNewMessage={this.props.closeNewMessage}
                    openNewMessage={this.props.openNewMessage}
                />
                <MessageLinkPopup id="listner_Message" showMsgModal={this.state.openMessageLink} closeMsgModal={this.closeMsgModal} closeModalDialog={this.closeNewMessage}
                    copiedLinked={""} currentUserId={1}
                />
            </Box>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
export default CommentListCP;
// Customizable Area End