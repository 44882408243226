Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "BulkUploading";
exports.labelBodyText = "BulkUploading Body";
exports.exampleApiContentTypeUpload = "Uploading";
exports.textInputPlaceReset = "Enter Upload Text";
exports.labelReplace = "Rset password uploading";
exports.labelBodyReset = "BulkUploading Body Reset";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End