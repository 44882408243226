// Customizable Area Start
import "antd/dist/antd.css";
import { ForgotPasswordBackground, ForgotPShade} from "./assets";
import { LeftOutlined } from "@ant-design/icons";
import { Row, Col, Form, Button } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "../../otp-input-confirmation/src/otp.css"
import axios from "axios";
import OtpInput from 'react-otp-input';
import { openNotification } from "../../../components/src/Notification.web";
import MyTimer from "../../../components/src/TimerWeb";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { nxzborderlogo } from "../../email-account-registration/src/assets";
const urlConfig = require("./../../../framework/src/config")
const baseUrl = urlConfig.baseURL;

export default function OtpVerifyWeb() {
  const history = useHistory();
  const [otp, setOtp] = useState('')
  const token: any = localStorage.getItem("forget_token")

  const onFinish = async (values: any) => {

    // const data = {
    //   // "otp": parseInt(values.otp),
    //   "otp": [parseInt(values.otp)],
    //   // token:token
    // }
    if (values.otp) {
      await axios
        .put(
          `${baseUrl}/account_block/otp_verification`, 
         { 
          otp: [parseInt(values.otp)] 
         },
        {
         headers: {
          "Content-Type": "application/json", 
          token: token, 
        },
        }
        )
        .then((res) => {
          history.push("/reset-password")
          openNotification("OTP match Succesfully", "Success")

        })
        .catch((err) => {

          openNotification("Please enter valid OTP", "Error")
          // err.response.status === 401 ? history.push("/AccountType"):""
          // err.response.status === 401||400 ? 
          //   history.push("/forgot-password")
          //   : ""
          // });
        });
    } else {

      openNotification("Please Enter OTP", "Error")
    }

  };
  const handleOTPChange = (otp: any) => {
    setOtp(otp);
  }
  const [showResendOTP, setShowResendOTP] = useState<boolean>(false);
  const time = new Date();
  time.setSeconds(time.getSeconds() + 150);
  const [form] = Form.useForm();
  const handleResend = async () => {
    form.resetFields();
    await axios
      .put(`${baseUrl}/account_block/resend_otp`, '', {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          
          token: token,
        },
      })
      .then((res) => {
        setShowResendOTP(false)
        openNotification(res?.data?.message, "Success")
        // history.push("/TermAndConditions")
      })
      .catch((err) => {
        if(err.response.status === 401 ){
          openNotification("Token has Expired", "Error")
          history.push("/AccountType")
        }else{
          openNotification(err?.response?.data?.errors[0], "Error")

        }
       
      });
  }
  return (
    <Row className="bgDark" style={{ height: "100%" }} >
      {/* <Row justify="space-around"> */}
    <Grid item lg={6} md={6} xs={12} className="bgImg-welcome-create" >
        <img 
        className="image1" 
        src={ForgotPasswordBackground} />
          <img
           className="image2" 
           src={ForgotPShade} />
        </Grid>
        <Grid item lg={6} md={6} xs={12} 
        className="welcome-form ">

        <Row
          align="middle"
          justify="center"
          style={
            {
            height: "80%",
            width: "75%",
            margin: "auto",
            textAlign: "center",
          }
        }
        >
          <Col 
          xxl={15} xl={18} lg={20} md={21} sm={16} xs={21}>
            {/* <div style={{ width: "75%", margin: "auto", textAlign: "center" }}> */}
            {/* <div className="otp-text"> */}
            <Row justify="center" className="welcomeLogo">
                <img src={nxzborderlogo} alt="App Logo" />
              </Row>
              <Row justify="center" className="welcome-text">
              <Row
                justify="center"
                className=" width_100 text_white mt-1  font_22 width_100 font_W_600 inter"
              >
                We Need To Verify Its Really You
              </Row>
              <Row justify="center" className="text_white1 width_100 inter">
                Enter the OTP we just Text to you
              </Row>
              </Row>
            {/* </div> */}
            {/* <Row  justify="center" className=""> */}
            <Form
              name="basic"
              className="login_form"
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
              form={form}
            >
              <Row justify="center" >
                <Form.Item 
                  className="otp-input login_form"
                  name="otp"
                  rules={[
                    { required: true, message: "Please enter OTP" },
                    { pattern: /^\d+$/, message: "Only numbers are allowed" },
                  ]}
                  >
                  <OtpInput
                    onChange={handleOTPChange}
                    numInputs={4}
                    separator={<span>-</span>}
                    isInputNum={true}
                  /></Form.Item>
              </Row>
              {/* <Row justify="center"> */}
              {/* <Col lg={24} className="otp-button w_100"> */}
              <Row justify="center" className="time">
                {showResendOTP ?
                  <Button type="text" onClick={handleResend} className="gray1">
                    Didn't Get OTP?<span
                      className="text_red pl_05 cursor_pointer"
                    >RESEND</span> </Button> :
                  <MyTimer expiryTimestamp={time} setShowResendOTP={setShowResendOTP} showResendOTP={showResendOTP} />}</Row>
              {/* <button
                      type="button"
                      onClick={() => {
                        history.push("/TermAndConditions");
                      }}
                    >
                      CONTINUE
                    </button> */}
              <Form.Item  style={{marginTop:"10%"}}> <Button
                className="red_custom_btn login_btn "
                type="primary"
                htmlType="submit"
              // onClick={() => {
              //   history.push("/TermAndConditions");
              // }}
              >
                Verify OTP
              </Button>
              
              <Button
                    className="next_back_account_create back_btn inter"
                       onClick={() => history.goBack()}
                      type="primary"
                      htmlType="submit"
                    >
                      Back
                    </Button></Form.Item>
              {/* </Col> */}
              {/* </Row> */}
            </Form>
            {/* </Row> */}
          </Col>
        </Row>
      </Grid>
      {/* </Row> */}
    </Row>
  );
}
// Customizable Area End
