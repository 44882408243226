// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import type { RcFile } from 'antd/es/upload/interface';
import { message } from 'antd';
import type { FormInstance } from 'antd/es/form';
import MessageEnum, { getName } from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { openNotification } from "../../../../../components/src/Notification.web";
export const configJSON = require('../../config');
import { AddUserIcon, Group3, PlaylistCoverAdd } from "./assets.web";


interface S {
  values: any;
  cover: any;
  apiToken: any;
  privateActive: any;
  publicActive: any;
  createPlaylist: any;
  playlistsList: any;
  addPlaylist: any;
  userPlaylists: any
  loading: any;
}
interface SS {
}
interface Props {
  id: string;
  open: any;
  handleClose: any;
  playlistType: any;
  trackData: any;
  queuePopupClose:any;
}

export default class CreateAudioPlaylistPopupController extends BlockComponent<
  Props,
  S,
  SS
> {
  
  apiGetUserPlaylistsCallId: string = "";
  apiGetUserVideoPlaylistsCallId: string = "";
  apiAddTrackToPlaylistCallId: string = "";
  apiAddPlaylistToPlaylistCallId: string = "";
  apiCreatePlaylistCallId: string = "";
  apicreateAxiosPlaylist:any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      values: {
        Playlist_cover: '',
        playlist_name: '',
        playlist_status: 'public_playlist'
      },
      cover: '',
      apiToken: localStorage.getItem('token'),
      privateActive: false,
      publicActive: true,
      createPlaylist: false,
      playlistsList: [
        {
          id: 1,
          cover_image: PlaylistCoverAdd,
          Name: 'Drone',
          Tracks: '27 Tracks'
        },
        {
          id: 2,
          cover_image: PlaylistCoverAdd,
          Name: 'Drone',
          Tracks: '31 Tracks'
        },
        {
          id: 3,
          cover_image: PlaylistCoverAdd,
          Name: 'Drone',
          Tracks: '27 Tracks'
        },
        {
          id: 4,
          cover_image: PlaylistCoverAdd,
          Name: 'Drone',
          Tracks: '31 Tracks'
        },
        {
          id: 5,
          cover_image: PlaylistCoverAdd,
          Name: 'Drone',
          Tracks: '27 Tracks'
        },
        {
          id: 6,
          cover_image: PlaylistCoverAdd,
          Name: 'Drone',
          Tracks: '27 Tracks'
        },
        {
          id: 7,
          cover_image: PlaylistCoverAdd,
          Name: 'Drone',
          Tracks: '27 Tracks'
        },
        {
          id: 8,
          cover_image: PlaylistCoverAdd,
          Name: 'Drone',
          Tracks: '27 Tracks'
        },
      ],
      addPlaylist: [],
      userPlaylists: [],
      loading: false
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  formRef = React.createRef<FormInstance>();
   getBase64(video: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(video);
  }

  async componentDidMount() {
    super.componentDidMount();
    if(this.props.playlistType == 'audio'){
      this.getUserPlaylists()
     }else{
      this.getUserVideoPlaylists()
    }
  }

 
  
  onFinish = (values: any) =>{
    
    let formData = new FormData();
            
    formData.append('name', values.Playlist_name);
    formData.append('cover_image', values.Playlist_cover);
    formData.append('status', values.Playlist_status);
    formData.append('play_list_type', this.props.playlistType == 'audio' ? 'audio' : 'video');

     this.createAxiosPlaylist(values)
  }

  // add to playlist popup

  createAddToPlaylist = () =>{
    this.setState({createPlaylist: false})
  }

  createPlaylist = () =>{
    this.setState({createPlaylist: true})
  }

  playlistAdd = (playlist: any) => {
    if(!this.state.addPlaylist.includes(playlist.id)){
      this.setState({addPlaylist: [playlist.id, ...this.state.addPlaylist]})
    }
  }

  setPlaylist = (playlist: any) =>{
    if(this.state.addPlaylist.includes(playlist.id)){
     return <div style={{color: 'white'}}><img src={AddUserIcon} /></div>
    }else{
      return <div style={{color: 'white'}}><img src={Group3} /></div>
    }
  }

  setPrivate = (e: any) =>{
    this.setState({privateActive: true, publicActive: false })
    this.formRef.current?.setFieldsValue({ Playlist_status: e.target.value });
  }

  setPublic = (e: any) =>{
    this.setState({privateActive: false, publicActive: true})
    this.formRef.current?.setFieldsValue({ Playlist_status: e.target.value });
  }

   beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  handleChange = async (options: any) => {
    const { file } = options;
    this.getBase64(file, (imageUrl: any) => {
      this.formRef.current?.setFieldsValue({ Playlist_cover: file });
      const {values} = this.state;
      values.Playlist_cover = imageUrl;
      this.setState({values});
    }) 
    const fmData: any = new FormData();
    fmData.append(`cover_image`, file);
   
}

songInPlaylist = (data: any) => {
  if(data?.length> 0){
    data?.map((playlist: any) =>{
       playlist?.attributes?.playlist_items?.data.map((song: any) => {
         if(song.id == this.props.trackData.id){
           this.playlistAdd(playlist)
         }
       })
    })
  }
}
createAxiosPlaylist = async (values: any) =>{
  let formData = new FormData();
         
 formData.append('name', values.Playlist_name);
 formData.append('cover_image', values.Playlist_cover);
 formData.append('status', values.Playlist_status);
 formData.append('play_list_type', this.props.playlistType == 'audio' ? 'audio' : 'video');


 this.setState({loading: true})

 const header = {
   "token": localStorage.getItem('token')
 };

 const requestMessage = new Message(
   getName(MessageEnum.RestAPIRequestMessage)
 );
 this.apicreateAxiosPlaylist = requestMessage.messageId;
 requestMessage.addData(
   getName(MessageEnum.RestAPIResponceEndPointMessage),
   configJSON.baseUrl + `${configJSON.createPlaylist}`
 );

 requestMessage.addData(
   getName(MessageEnum.RestAPIRequestHeaderMessage),
   JSON.stringify(header)
 );
 requestMessage.addData(
   getName(MessageEnum.RestAPIRequestBodyMessage),
   formData
 );
 requestMessage.addData(
   getName(MessageEnum.RestAPIRequestMethodMessage),
   configJSON.postAPIMethod
 );

 runEngine.sendMessage(requestMessage.id, requestMessage);
 return true;
 
}
getUserPlaylistApiSuccessCall = (responseJson: any) => {
  if (responseJson != null) {
    if (!responseJson.error) {
      if(responseJson.hasOwnProperty("message")){
        this.setState({loading: false})
        openNotification(responseJson?.message, 'Error')
      }else{
        this.setState({userPlaylists: responseJson.data})
        this.setState({loading: false})
        this.songInPlaylist(responseJson.data)
      }
    } else {
      openNotification(responseJson?.error, 'Error')
      this.setState({loading: false, userPlaylists: []})
      this.props.handleClose()
    }
  }
 }

 addTrackToPlaylistSuccess = (responseJson: any) =>{
  if (responseJson != null) {
    if (!responseJson.error) {
      if (responseJson && responseJson.data && responseJson.data[0] && responseJson.data[0].message) {
  openNotification(responseJson.data[0].message, 'Error');
  this.props.handleClose();
}

      else{
        openNotification('song added to playlist', 'Success')
        this.setState({loading: false})
      }
    } else {
      openNotification(responseJson?.error, 'Error')
      this.setState({loading: false})
      this.props.handleClose()
    }
  }
 }
 addPlaylistToPlaylist = (playlist: any) =>{
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    'token': this.state.apiToken
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiAddPlaylistToPlaylistCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.baseUrl + `/bx_block_playlist/merge_playlists?current_playlist_id=${this.props.trackData.id}&play_list_id=${playlist.id}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getAPIMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
 }

 addPlaylistToPlaylistSuccess = (responseJson: any) =>{
  if (responseJson != null) {
    if (!responseJson.error) {
      openNotification('songs added to playlist', 'Success')
      this.setState({loading: false})
      this.props.handleClose()
    } else {
      openNotification(responseJson?.error, 'Error')
      this.setState({loading: false})
      this.props.handleClose()
    }
  }
 }


 getUserPlaylists = () => {
  this.setState({loading: true})

  const header = {
    "Content-Type": configJSON.dashboarContentType,
    'token': this.state.apiToken
  };
  
  
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiGetUserPlaylistsCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.baseUrl + `${configJSON.getUserPlaylists}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getAPIMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);

}

getUserVideoPlaylists = () => {
  this.setState({loading: true})

  const header = {
    "Content-Type": configJSON.dashboarContentType,
    'token': this.state.apiToken
  };
  
  
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiGetUserVideoPlaylistsCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.baseUrl + `${configJSON.getVideoUserPlaylists}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getAPIMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);

}

addSongorPlaylistToPlaylist = (playlist: any) =>{
   if(this.props.trackData.type == 'upload_type_track'||this.props.trackData.type == 'track'||this.props.trackData.type == 'video'){
     this.addTrackToPlaylist(playlist)
   }
   else{
    this.addPlaylistToPlaylist(playlist)
   }
 }

addTrackToPlaylist = (playlist: any) => {
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    'token': this.state.apiToken
  };
  
  const data = {
            
    add_track_id: this.props.trackData.id,
    play_list_id: playlist?.id

};
  
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiAddTrackToPlaylistCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.baseUrl + `${configJSON.addTrackToPlaylist}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(data)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.postAPIMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);

}

createNewPlaylist = (formData: any) => {
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    'token': this.state.apiToken
  };
  
 
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiCreatePlaylistCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.baseUrl + `${configJSON.createPlaylist}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(formData)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.postAPIMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);

}

createPlaylistSuccess = (responseJson: any) =>{
  if (responseJson != null) {
    if (!responseJson.error) {
      openNotification('New Playlist Created', 'Success')
    } else {
      openNotification(responseJson?.error, 'Error')
      this.props.handleClose()
    }
  }
}

createAxiosPlaylistSuccess = (responseJson: any) =>{
  if (responseJson != null) {
    if (!responseJson.error) {
      openNotification('New Playlist Created Successfully', 'Success')
      this.addTrackToPlaylist(responseJson.data)
      this.props.handleClose()
      this.props.queuePopupClose()
    } else {
      openNotification('Some Error Has Occurred', 'Error')
      this.setState({loading: false})
      this.props.handleClose()
      this.props.queuePopupClose()
    }
  }
}

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.apiGetUserPlaylistsCallId||apiRequestCallId === this.apiGetUserVideoPlaylistsCallId) {
        this.getUserPlaylistApiSuccessCall(responseJson)
        this.parseApiCatchErrorResponse(errorReponse);
      } 
      else if (apiRequestCallId === this.apiAddTrackToPlaylistCallId) {
        this.addTrackToPlaylistSuccess(responseJson)
        this.parseApiCatchErrorResponse(errorReponse);
      }else if (apiRequestCallId === this.apiAddPlaylistToPlaylistCallId) {
        this.addPlaylistToPlaylistSuccess(responseJson)
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apiCreatePlaylistCallId) {
        this.createPlaylistSuccess(responseJson)
        this.parseApiCatchErrorResponse(errorReponse);
      }else if(apiRequestCallId === this.apicreateAxiosPlaylist){
        this.createAxiosPlaylistSuccess(responseJson)
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

}
// Customizable Area End