// Customizable Area Start
import { Content } from "antd/lib/layout/layout";
import {  Layout, Row } from 'antd';
import React from 'react';
import "../../../Playlist.css";
import {
  Hidden
} from "@material-ui/core";
import TopPlaylist from "../TopPlaylist.web";
import PlaylistBanner from '../../PlaylistCardNew/PlaylistBanner.web'
import SinglePlaylistController from "./SinglePlaylistController";
import { nxzloader } from "../../../../../dashboard/src/assets";
import HomeHeader from "components/src/CustomChart/PopUps/HomeHeader.web";
import AsideLeft from "components/src/CustomChart/PopUps/AsideLeft.web";
import { AudioContextApi } from "../../../../../dashboard/src/AudioContext/AudioContext.web";

export default class SelfView extends SinglePlaylistController {
  render() {
    const { isPlaylist } = this.context;
    const {adsData, selfPlaylistLoader, follow, showPlaylist, follower, playlistData, handleplay, musicButton,
      playList, openPopup,openPopUp, openEditPopup, openDeletePopup, openLinkPopup, openMessageLink, copied, selectedDiv, handleAudioVideo, playlistType, privatetoggle, publicData, selectedPlaylistId, repost_track,
      displayedArray

    }: any = this.state;
    return (

      <>
        <div
          className="playListMain">
          <Layout style={{ backgroundColor: "black", height: "100vh" }}>
            <Hidden smDown>
              <AsideLeft />
            </Hidden>
            <Content
              style={{
                margin: "0px",
                backgroundColor: "black",
              }}
            >
              <HomeHeader />

              {selfPlaylistLoader ? (
                <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
                  <img src={nxzloader} style={{ width: "5%" }} />
                </Row>
              ) : (
                <div className={isPlaylist ? "mainBody-audio-withoutheight" : "mainBody-audio-withoutaudio"}>
                  <PlaylistBanner
                    setHandleAudioVideo={(value: any) => this.setState({ handleAudioVideo: value })}
                    handleplay={handleplay}
                    setHandlePlay={(value: any) => this.setState({ handleplay: value })}
                    playList={showPlaylist}
                    handlePlayList={this.handleSelectedSongPlay}
                    id={showPlaylist?.id}
                    artist_id={showPlaylist?.attributes?.artist_id}
                    cover_image={showPlaylist?.attributes?.cover_image}
                    playlistName={showPlaylist?.attributes?.name}
                    song_count={showPlaylist?.attributes?.song_count}
                    avatar={showPlaylist?.attributes?.avatar}
                    artist_name={showPlaylist?.attributes?.artist_name}
                    user_type={showPlaylist?.attributes?.user_type}
                    handleRepostPlaylist={this.handleRepostPlaylist}
                    handleFollowStatus={follow}
                    play_count={showPlaylist?.attributes?.play_count}
                    following_count={showPlaylist?.attributes?.following_count}
                    playListData={playlistData}
                    followers_count={follower}
                    repost_count={showPlaylist?.attributes?.repost_count}
                    handleFollow={() => this.handleFollow(showPlaylist?.attributes?.artist_id)}
                    handleUnfollow={() => this.handleUnfollow(showPlaylist?.attributes?.artist_id)}
                  />
                  {
                    showPlaylist && showPlaylist.attributes.playlist_items.data.length === 0 ? (<>
                      <div
                      // className="song-list"
                      >
                        <div className="song-div mostly-customized-scrollbar"><Row align="middle" justify="center" style={{ height: "180px" }} className="no_data_available">
                          No Song Available
                        </Row></div></div>
                    </>) :
                      <div className="track_listing">
                        <TopPlaylist
                          musicButton={musicButton}
                          playList={playList}
                          openPopup={openPopUp}
                          playlistData={playlistData}
                          openEditPopup={openEditPopup}
                          openDeletePopup={openDeletePopup}
                          openLinkPopup={openLinkPopup}
                          openMessageLink={openMessageLink}
                          copied={copied}
                          showPlaylist={showPlaylist}
                          selectedDiv={selectedDiv}
                          handleAudioVideo={handleAudioVideo}
                          playlistType={playlistType}
                          privatetoggle={privatetoggle}
                          publicData={publicData} selectedPlaylistId={selectedPlaylistId}
                          setOpenUp={()=>this.setState({ openPopup: false })}
                          setOpenEditPopup={()=>this.setState({ openEditPopup: false })}
                          setHandleLinkCopy={()=>this.setState({ openLinkPopup: false, copied: false })}
                          setOpenMessageLink={()=>this.setState({ openMessageLink: false })}
                          setSelectedDiv={(data: any) =>this.setState({ selectedDiv: data })}
                          setHandleAudioVideo={(value: any) => this.setState({ handleAudioVideo: value })}
                          handleDeletePlayListClick={(id: any) => this.handleDeleteSong(id)}
                          handlerSingleVideos={( songId:any, songVideo: any) => {
                            this.handlerSingleVideos( songId, songVideo)}
                          }
                          onCloseRepost={this.handleRepostClose}
                          repost_track={repost_track}
                          respostSubmit={this.respostSubmit}
                          _handleTextFieldChange={this.handleCaptionChange}
                          displayedArray={displayedArray}
                          setOpenEditPopupTrue={()=>this.setState({ openEditPopup: true })}
                          setOpenMessageLinkTrue={()=>this.setState({ openMessageLink: true })}
                          handleChangeStatusOfPlaylist={(data: any) => this.handleChangeStatusOfPlaylist(data)}
                          privatetoggleToggle={()=>this.setState({ privatetoggle: false, displayedArray: this.state.privateData })}
                          changeMusicToogleFalse={this.changeMusicToogleFalse}
                          changeMusicToogleTrue={this.changeMusicToogleTrue}
                          handleSelectedSongPlay={( song:any, publicData:any, index: any) => this.handleSelectedSongPlay(song, publicData, index)}
                          handleRepost={this.handleRepost}
                          UpdateDetails={this.getShowPlaylistByID}
                          deleteTrackFromPlaylist={this.deleteTrackFromPlaylist}
                          inSingleCopy={() => this.onSingleCopy(showPlaylist)}
                          linkopenPopup={this.linkopenPopup}
                          CopiedForLink={this.state.CopiedForLink}
                          adsData={adsData}
                        />
                      </div>
                  }
                </div>
              )}
            </Content>
          </Layout>
        </div>



      </>
    );
  }
}
SelfView.contextType = AudioContextApi;
// Customizable Area End

