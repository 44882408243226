// Customizable Area Start
import { Input } from "antd";
import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { styled } from "@material-ui/styles";
import { searchshape } from "../../../../dashboard/src/assets";
import AudioVideoPlaylistController from "./AudioVideoPlaylisController";
import { playButton } from "blocks/dashboard/src/assets";
import CtrlFilterPopup from "../Common/CtrlFilterPopup.web";

const DivWrapper = styled(Box)({
  display: "flex",
  padding: "30px 50px 0px 27px",
  gap: "1%",
  minHeight: "calc(100% - 3rem)",
  justifyContent: "space-between",

  "@media(max-width: 1024px)": {
    flexDirection: "column",
    padding: "0px 10px 0px 5px",
    minHeight: "0px",
    gap: "0%",
  },
});

const Splitleftsidecontainer = styled(Box)({
  width: "46%",
  display: "flex",
  height: "max-content",
  paddingBottom: "20px",
  flexDirection: "column" as "column",
  gap: "15px",
  "@media(max-width: 1024px)": {
    width: "auto",
    height: "auto",
    gap: "15px",
    marginTop: "10px",
  },
});
const LeftsideAlltracksboxwrapper = styled(Box)({
  overflowY: "auto",
  maxHeight: "693px",
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    width: "0px",
    background: "transparent",
  },
});

const TotalSplits = styled(Box)({
  width: "100%",
  color: "white",
  "@media(max-width: 600px)": {
    height: "auto",
  },
});
const Borderboxweb = styled(Box)({
  "@media(max-width: 1024px)": {
    display: "none",
  },
});

const SplitboxRightsidecontainer = styled(Box)({
  // display: "flex",
  flexDirection: "column" as "column",
  width: "48%",
  gap: "30px",
  paddingBottom: "20px",
  alignItems: "center",
  "@media(max-width: 1024px)": {
    width: "auto",
    height: "auto",
    marginTop: "10%",
    gap: "20px",
  },
});

const LeftsideAlltracksbox = styled(Box)({
  width: "100%",
  "@media(max-width: 1024px)": {
    width: "100%",
  },
});

const TrackName = styled(Box)({
  fontSize: "22px",
  fontWeight: 700,
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "20px",
  letterSpacing: "normal",
  whiteSpace: "nowrap" as "nowrap",
  color: "#ffffff",
  fontFamily: "Inter",
  "@media(max-width: 450px)": {
    fontSize: "14px",
  },
});

const Inputboxwrapper = styled(Box)({
  width: "100%",
  background: "#212121",
  position: "relative",
  borderRadius: "27px",
  padding: "10px",
  "& .ant-input": {
    backgroundColor: "#212121",
    width: "88%",
    color: "white",
  },
  "& .ant-input-affix-wrapper": {
    background: "none",
    border: "unset",
  },
  "@media(max-width: 1024px)": {
    width: "100%",
    "& .ant-input": {
      width: "50%",
    },
  },
});

const RightsideAlltracksboxwrapper = styled(Box)({
  overflowY: "scroll" as "scroll",
  maxHeight: "676px",
  display: "grid",
  scrollbarWidth: "none",
  overflowX: "hidden",
  gap: "15px",
  gridTemplateColumns: "repeat(4, 1fr)",
  width: "fit-content",
  "@media(max-width: 1700px)": {
    // justifyContent:"center",
    gridTemplateColumns: "repeat(4, 1fr)",
  },
  "@media(max-width: 1550px)": {
    // justifyContent:"center",
    gridTemplateColumns: "repeat(3, 1fr)",
  },
  "@media(max-width: 1300px)": {
    // justifyContent:"center",
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  "@media(max-width: 1024px)": {
    // justifyContent:"center",
    gridTemplateColumns: "repeat(4, 1fr)",
  },

  "@media(max-width: 768px)": {
    // justifyContent:"center" ,
    gridTemplateColumns: "repeat(3, 1fr)",
  },
  "@media(max-width: 450px)": {
    // justifyContent:"center",
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  "&::-webkit-scrollbar": {
    display: "none",
    width: "0px",
  },
});

const PlaylistboxRightside = styled(Box)({
  width: "fit-content",
  // height: "50vh",
  display: "flex",
  minWidth: "100%",
  flexDirection: "column" as "column",
  gap: "15px",
  "@media(max-width: 1024px)": {
    minWidth: "100%",
    // width:"auto",
    // height:"auto",
    // marginTop:" 22%",
    // gap:"20px"
  },
});
const VideolistboxRightside = styled(Box)({
  width: "fit-content",
  // height: "50vh",
  display: "flex",
  minWidth: "100%",
  flexDirection: "column" as "column",
  gap: "15px",
  "@media(max-width: 1024px)": {
    // width:"auto",
    // height:"auto",
    // marginTop:" 22%",
    // gap:"20px"
  },
});
const VideosAlltracksboxwrapper = styled(Box)({
  overflowY: "scroll" as "scroll",
  maxHeight: "676px",
  scrollbarWidth: "none",
  display: "grid",
  gap: "15px",
  overflowX: "hidden",
  gridTemplateColumns: "repeat(2, 1fr)",

  "@media(max-width: 1700px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  "@media(max-width: 1624px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },

  "@media(max-width: 1550px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  "@media(max-width: 1300px)": {
    gridTemplateColumns: "repeat(1, 1fr)",
  },
  "@media(max-width: 1024px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },

  "@media(max-width: 768px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  "@media(max-width: 600px)": {
    gridTemplateColumns: "repeat(1, 1fr)",
    placeItems: "center",
  },
  "&::-webkit-scrollbar": {
    width: "0px",
    background: "transparent",
  },
});

export default class AudioVideoPlaylist extends AudioVideoPlaylistController {
  constructor(props: any) {
    super(props);
  }

  rigthsideAudioVideo = () => {
    if (this.props.selectedTab == "audio") {
      return (
        <PlaylistboxRightside>
          <Box style={{ width: "100%" }}>
            <Box style={this.props.webStyle.tracksbutton}>
              <Box style={{ width: "50%" }}>
                <Button
                  style={
                    this.props.selectedTab == "audio"
                      ? this.props.webStyle.activeaudiovideobutton
                      : this.props.webStyle.audiovideobutton
                  }
                  onClick={() => {
                    this.props.getAllPlaylist("audio");
                    this.props.setSelectedTabAudio();
                  }}
                  data-test-id="ctrl-audio"
                >
                  Audio
                </Button>
              </Box>
              <Box style={{ width: "50%" }}>
                <Button
                  style={this.props.webStyle.audiovideobutton}
                  onClick={() => {
                    this.props.getAllPlaylist("video");
                    this.props.setSelectedTabVideo();
                  }}
                  data-test-id="ctrl-video"
                >
                  Video
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            style={{
              display: " flex ",
              alignItems: "center",
              justifyContent: " space-between ",
              
            }}
          >
          <Typography style={this.props.webStyle.trackname}>
          <span style={this.props.webStyle.bluetext}>
            Top {this.props.top40playList.length< 40 ? this.props.top40playList.length : 40}
            </span> Playlists</Typography>
          <Typography style={this.props.webStyle.splittext}>This week</Typography>
        </Box>
          <Box style={{ width: "100%"}}>
            {this.props.top40playList?.length>0 ? (
              <RightsideAlltracksboxwrapper>
                {this.props.top40playList.map((item: any, index: any) => {
                  return (
                    <Box
                      key={index}
                      className="cursor-pointer"
                      onClick={() => this.props.openPlaylistScreen(item)}
                    >
                      <img
                        src={item.attributes?.cover_image}
                        className="tracks_image"
                        style={{objectFit:"cover"}}
                      />

                      <Typography style={this.props.webStyle.audioimagetext}>
                        {index + 1}. {`${item.attributes?.name.slice(0, 17)}`}
                      </Typography>
                      <Typography style={this.props.webStyle.audioartistext}>
                        {item.attributes.user_role}
                      </Typography>
                    </Box>
                  );
                })}
              </RightsideAlltracksboxwrapper>
            ) : (
              <Typography
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: 16,
                  fontWeight: 600,
                  color: "#fff",
                  // padding: "5px",
                  justifyContent: "center",
                  width: "100%",
                  paddingTop: "78px",
                }}
              >
                Data Not Found.
              </Typography>
            )}
          </Box>
        </PlaylistboxRightside>
      );
    } else if (this.props.selectedTab == "video") {
      return (
        <VideolistboxRightside>
          <Box style={{ width: "100%" }}>
            <Box style={this.props.webStyle.tracksbutton}>
              <Box style={{ width: "50%" }}>
                <Button
                  style={this.props.webStyle.audiovideobutton}
                  onClick={() => {
                    this.props.getAllPlaylist("audio");
                    this.props.setSelectedTabAudio();
                  }}
                  data-test-id="ctrl-audios"
                >
                  Audio
                </Button>
              </Box>
              <Box style={{ width: "50%" }}>
                <Button
                  style={
                    this.props.selectedTab == "video"
                      ? this.props.webStyle.activeaudiovideobutton
                      : this.props.webStyle.audiovideobutton
                  }
                  onClick={() => {
                    this.props.getAllPlaylist("video");
                    this.props.setSelectedTabVideo();
                  }}
                  data-test-id="ctrl-videos"
                >
                  Video
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            style={{
              display: " flex ",
              justifyContent: " space-between ",
            }}
          >
            <Typography style={this.props.webStyle.trackname}>
              <span style={this.props.webStyle.bluetext}>Top {this.props.top40playList.length}</span> Playlists
            </Typography>

            <Typography style={this.props.webStyle.splittext}>
              This week
            </Typography>
          </Box>
          <Box style={{ width: "100%" }}>
            {this.props.top40playList?.length > 0 ? (
              <VideosAlltracksboxwrapper>
                {this.props.top40playList.map((item: any, index: any) => {
                  return (
                    <Box
                      key={index}
                      className="video_image_tracks"
                      style={{
                        borderRadius: "10px",
                        position: "relative",
                        background: `url("${item.attributes?.cover_image}") lightgray 50% / cover no-repeat`,
                        display: "flex",
                        alignItems: "end",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                      onClick={() => {
                        this.props.selectedsong(
                          index,
                          item.attributes?.id,
                          item.attributes?.playlist_items?.data.length
                        );
                      }}   
                                         
                    >
                      <Box className="image_border">
                        <Typography style={this.props.webStyle.videoImagetex}>
                          {item.attributes.name}
                        </Typography>
                        <Typography style={this.props.webStyle.videoArtisttext}>
                          {item.attributes.playlist_items?.data?.length} videos
                        </Typography>
                      </Box>
                      <img
                        src={playButton}
                        style={{
                          height: "49px",
                          width: "49px",
                          position: "absolute",
                          right: "45%",
                          top: "30%",
                        }}
                      />
                    </Box>
                  );
                })}
              </VideosAlltracksboxwrapper>
            ) : (
              <Typography
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: 16,
                  fontWeight: 600,
                  color: "#fff",
                  padding: "5px",
                  justifyContent: "center",
                  width: "100%",
                  paddingTop: "78px",
                }}
              >
                Data Not Found.
              </Typography>
            )}
          </Box>
        </VideolistboxRightside>
      );
    }
  };

  render() {
    const {
      searchFilterFunction,
      webStyle,
      playList,
      selectedsong,
      selectedTab,
      
    } = this.props;
    return (
      <DivWrapper>
        <Splitleftsidecontainer>
          <Inputboxwrapper >
            <Input
              data-test-id="test_search_term_id_handle_search_input"
              onChange={(text: any) => searchFilterFunction(text)}
              className="playlistctrl12"
              value={this.props.searchText}
              prefix={
                //@ts-ignore
                <SearchOutlined className="text_white1"
                
                />
              }
              placeholder="Search Plyalists"
            />
            <img src={searchshape} style={webStyle.searchimage}
            onClick={()=>{this.handleOpenPlaylist()}}
            data-test-id="openFilterpopup"
            />
          </Inputboxwrapper>

          <TotalSplits>
            <Typography style={webStyle.trackname}>
              <span style={webStyle.bluetext}>{playList?.length}</span>{" "}
              Playlists
            </Typography>
          </TotalSplits>
          <LeftsideAlltracksbox>
            <LeftsideAlltracksboxwrapper>
              {playList?.length > 0 ? (
                playList.map((item: any, index: any) => {
                  let typeColor = "#919191";
                  let status = "draft";

                  if (
                    item.attributes?.status.toLowerCase() === "public_playlist"
                  ) {
                    typeColor = "red";
                    status = "Public";
                  } else if (
                    item.attributes?.status.toLowerCase() === "private_playlist"
                  ) {
                    typeColor = "#3959f9";
                    status = "Private";
                  }
                  return (
                    <Box
                      key={index}
                      style={webStyle.leftSideSingletrackbox}
                      className={
                        item.attributes?.playlist_items?.data.length > 0
                          ? "cursor-pointer"
                          : "cursor-notAllowed"
                      }
                      onClick={() => {
                        selectedsong(
                          index,
                          item.attributes?.id,
                          item.attributes?.playlist_items?.data.length
                        );
                      }}
                      data-test-id="audioplaylist-selectSong"
                    >
                      <Box style={webStyle.singleArtinfo}>
                        <img
                          src={item.attributes?.cover_image}
                          style={
                            selectedTab == "audio"
                              ? webStyle.audiocard
                              : webStyle.videocard
                          }
                        />

                        <Box style={webStyle.leftsideownerstats}>
                          <TrackName>
                            {`${item.attributes?.name?.slice(0, 17)}`}
                          </TrackName>
                          <span style={webStyle.splittext}>
                            {item.attributes?.playlist_items?.data?.length}{" "}
                            {selectedTab == "audio" ? "Tracks" : "Videos"}
                          </span>
                        </Box>
                      </Box>
                      <Box style={webStyle.leftsideownerstats}>
                        <Box>
                          <Button
                            style={{
                              border: `1px solid ${typeColor}`,
                              borderRadius: "20px",
                              color: typeColor,
                              height: "23px",
                              textTransform: "capitalize",
                            }}
                          >
                            {status}
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  );
                })
              ) : (
                <Typography
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: 16,
                    fontWeight: 600,
                    color: "#fff",
                    padding: "5",
                    justifyContent: "center",
                    paddingTop: "78px",
                  }}
                >
                  Data Not Found.
                </Typography>
              )}
            </LeftsideAlltracksboxwrapper>
          </LeftsideAlltracksbox>
        </Splitleftsidecontainer>
        <Borderboxweb>
          <hr
            style={{
              height: "100%",
              width: "1px",
              border: "1px solid #212121",
            }}
          />
        </Borderboxweb>
        <SplitboxRightsidecontainer>
          {this.rigthsideAudioVideo()}
        </SplitboxRightsidecontainer>
        <CtrlFilterPopup
      open={this.state.openPlaylistFilter}
      handleClose={this.handleClosePlaylist}
      currentTab={selectedTab}
      handleTrack={(data:any)=>this.props.handlePlayList(data)}

      />
      </DivWrapper>
    );
  }
}

// Customizable Area End
