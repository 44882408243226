// Customizable Area Start
import React, { useMemo,useContext } from "react";
import { Box, Typography,IconButton, Hidden } from "@material-ui/core";
import { Row } from "antd";
import MediaDMCP from "./MediaDMCP.web";
import NewDMContact from './NewDMContact.web';
import ListofNewChat from "../ListofNewChat";
import DMChatDialog from '../DMChatDialog.web'
import DMProfileDialog from "../DMProfileDialog.web";
import { Menu as MenuIcon } from "@material-ui/icons";
import Search from "../../../dashboard/src/Inbox/Common/Search.web";
import { Message_Icon_Blue } from "../../../dashboard/src/CustomComponent/SharePlaylistPopup/assets";
import { nxzloader } from "../../../dashboard/src/assets";
import DMTypeSelector from "../../../dashboard/src/Inbox/Common/DMTypeSelector.web";
import IconMenu from "components/src/CustomChart/PopUps/IconMenu.web";
import { AudioContextApi } from "../../../dashboard/src/AudioContext/AudioContext.web";
import HomeHeaderDropdown from "components/src/CustomChart/PopUps/HomeHeaderDropdown.web";

const DMListCP = ({ onClearNewAddedUser,anchorEl,handleClose,handleMenu,newUserAddedState, tempTracks, selectedUserType, handlePageChange, searchText, setTrackIndex,selectedTrackIndex, loading, tracks, handleUserId, showSmallDMView, otherUserID, closeProfileMedia, navigation, id, closeNewMessage, openNewMessage, isShowMsgModal, handleAddNewUser, newUserAdded, viewUserDetail, showProfileDialog, particularUserDataRes, updateParticulartUserData, selectedParticularList, generalListLoading, followerListLoading,setOtherUserId,selectedTab,selectedTrackIndexId, handleBlockUser }: any) => {
    const { isPlaylist} = useContext(AudioContextApi);
    const returntabTitle = () => {
       let activeTab: any;
       if (selectedTab == "General") {
           activeTab = "General Users"
       } else if (selectedTab == "Followers") {
           activeTab = "Your Followers"
       } else {
           activeTab = "Your Subscribers"
       }
       return activeTab
   }


   const tabTitle = useMemo(() => returntabTitle(), [selectedTab])
   const closeMsgModal = (newUser: any) => {
       closeNewMessage()
       handleAddNewUser(newUser)
       setTrackIndex(newUser.id)
   }
   
   return (
    <Box style={{height:"100%",overflow:"hidden"}}>
           <DMTypeSelector selectedPage={selectedTab} selectPage={handlePageChange} />
           <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: "28px", alignItems: "center" }}>
           <Box style={{}} className="hide_in_desktop_menuicon">
                        <IconButton
                        style={{
                            color: "white",
                            padding: '5px'
                        }}
                        >
                        <MenuIcon className="menu-icon" style={{ width: '3rem', height: '2.5rem' }} onClick={handleMenu} />
                        </IconButton>
                        <IconMenu anchorEl= {anchorEl} handleClose={handleClose}/>
                        </Box>
                <Box style={{width: '80%'}}>
               <Search onSearchChange={(value: any) => {
                   searchText(value.target.value)
                   // searchDMs(value)
               }} placeholder="Search DMs" style={{ width: "100%" }} />
                 </Box>
               <img src={Message_Icon_Blue} style={{ height: '50px', width: '50px', borderRadius: '50%',marginRight: '2px', marginLeft: '5px', cursor: selectedTab == "Subs" ? "not-allowed" : "pointer" }}
                   onClick={() =>
                       selectedTab !== "Subs"&&
                        openNewMessage()
                      
                       }
               />
               <Hidden only={["lg",'md','xl','sm']}>
               <Box>
                <HomeHeaderDropdown comments={true}/>
               </Box>
               </Hidden>
             
           </Box>
           {loading ? (
               <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
                   <img src={nxzloader} style={{ width: "10%" }} />
               </Row>
           ) : (
               <>
                   <Box className="RepliesBox" style={{ display: "flex", flexDirection: "column" }}><Typography className="RepliesText"><span className="ReplyNoText">{tracks?.length}</span> DMs</Typography>
                       {selectedUserType != "listener" && <Box style={{ color: "#fff", fontWeight: 800, display: "flex" }}><Box style={{ color: "#8f92a1" }}>From: &nbsp; </Box>{tabTitle}</Box>}
                   </Box>
                   <Box style={{ height: '100%',paddingBottom:'15px'}}  className={isPlaylist?"CommentContainerScroll audio-inboxDMListContainer":"CommentContainerScroll inboxDMListContainer"}>
                   {newUserAddedState &&
                            <NewDMContact
                                media={tempTracks}
                                // type={track.attributes.track_upload_type} 
                                selected={false}
                                unread={tempTracks.attributes?.is_read}
                                onHandleUserId={handleUserId}
                                onHandleNotUserId={handleUserId}
                                onUserProfileClick={viewUserDetail}
                                TrackIndex={0}
                                setTrackIndex={setTrackIndex}
                                selectedTrackIndex={selectedTrackIndex}
                            />}
                        {
                            tracks?.length > 0 ?
                                tracks.sort((a:any, b:any) => {
                                    if (a.type === 'search' && b.type !== 'search') {
                                      return -1;
                                    } else if (a.type !== 'search' && b.type === 'search') {
                                      return 1;
                                    } else {
                                      return 0;
                                    }
                                  }).map((track:any, index:any) => {
                                    return (
                                        <React.Fragment key={track.id} >
                                            <MediaDMCP
                                            onClearNewAddedUser={onClearNewAddedUser}
                                                media={track}
                                                // type={track.attributes.track_upload_type} 
                                                selected={false}
                                                unread={track.attributes?.is_read}
                                                onHandleUserId={handleUserId}
                                                onHandleNotUserId={handleUserId}
                                                onUserProfileClick={viewUserDetail}
                                                TrackIndex={newUserAddedState ? 1 + index : index}
                                                setTrackIndex={setTrackIndex}
                                                selectedTrackIndex={selectedTrackIndex}
                                keyId={[track.id,""+track.attributes.receiver_id,""+track.attributes.sender_id]}

                                            />
                                        </React.Fragment>
                                    )
                                })
                                :
                                !newUserAddedState &&
                                  
                                        <div style={{ height: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                            <div style={{ color: 'white', fontSize: '20px', fontWeight: 800 }}> No Message</div>
                                        </div>
                                 
                        
                        }
                   </Box>
               </>)}
           <DMChatDialog
               isOpen={showSmallDMView}
               closeMediaDialog={closeProfileMedia}
               navigation={navigation}
               id={id}
               otherUserId={otherUserID}
               newUserAdded={newUserAdded}
               selectedTab={selectedTab}
               selectedTrackIndexId={selectedTrackIndexId}
           />
            <DMProfileDialog
                   isOpen={showProfileDialog}
                   id={id}
                   navigation={navigation}
                   otherUserId={otherUserID}
                   newUserAdded={newUserAdded}
                   closeMediaDialog={closeProfileMedia}
                   particularUserDataRes={particularUserDataRes}
                   updateParticulartUserData={updateParticulartUserData}
                   selectedParticularList={selectedParticularList}
                   setOtherUserId={setOtherUserId}
                   handleBlockUser={handleBlockUser}
               />
           {/* <MessageLinkPopup id = "listner_Message" open={this.state.openMessageLink} handleClose={() => {this.setState({openMessageLink: false})}} /> */}
           <ListofNewChat showMsgModal={isShowMsgModal} closeMsgModal={closeMsgModal} closeModalDialog={closeNewMessage} />


       </Box>
   );


}
export default DMListCP;
// Customizable Area End