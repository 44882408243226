// Customizable Area Start
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Box } from '@material-ui/core';
import { SlideTransition,dialogStyles } from '../../dashboard/src/Inbox/Common/Common.web';
import ParticularUser from './ParticularUser/index.web';

// Customizable Area End




// Customizable Area Start
interface Props {
    isOpen: boolean;
    closeMediaDialog: () => void;
    navigation:any;
    newUserAdded:()=>void;
    id:any;
    particularUserDataRes:any;
    updateParticulartUserData:()=>void;
    selectedParticularList:any;
    otherUserId:any;
    setOtherUserId:any;
    handleBlockUser:(id: number, isOpen:boolean) => void;

}
// Customizable Area End


// Customizable Area Start
export const DMProfileDialog = (props: Props) => {


    const classes = dialogStyles();
    return (
        <Dialog fullScreen open={props.isOpen} onClose={props.closeMediaDialog} TransitionComponent={SlideTransition} className='CommentChatDialog'>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={props.closeMediaDialog} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Comments
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '90vh', flex: 1, padding: '0px 8px' }}>
                <Box className='CommentChatBox'>
                   <ParticularUser
                     navigation={props.navigation}
                     id={props.id}
                     otherUserID={props.otherUserId}
                     particularUserDataRes={props.particularUserDataRes}
                     updateParticulartUserData={props.updateParticulartUserData}
                     selectedParticularList={props.selectedParticularList}
                     setOtherUserId={props.setOtherUserId}
                     handleBlockUser={props.handleBlockUser}
/>
                </Box >
            </Box>
        </Dialog >
    );
}
// Customizable Area End




// Customizable Area Start
export default DMProfileDialog;
// Customizable Area End