// Customizable Area Start
import React,{useContext} from "react";
import { Box, Typography,IconButton } from "@material-ui/core";
import NewDMContact from "./Creative/NewDMContact.web";
import MediaDMCP from "./Creative/MediaDMCP.web";
import ListofNewChat from "./ListofNewChat";
import { Row } from "antd";
import DMChatDialog from "./DMChatDialog.web";
import DMProfileDialog from "./DMProfileDialog.web";
import { Menu as MenuIcon } from "@material-ui/icons";
import Search from "../../dashboard/src/Inbox/Common/Search.web";
import { Message_Icon_Blue } from "../../dashboard/src/CustomComponent/SharePlaylistPopup/assets";
import { nxzloader } from "../../dashboard/src/assets";
import IconMenu from "components/src/CustomChart/PopUps/IconMenu.web";
import { AudioContextApi } from "../../dashboard/src/AudioContext/AudioContext.web";

const DMList = ({ onClearNewAddedUser,anchorEl,handleClose,handleMenu,newUserAddedState, tempTracks, showProfileDialog, particularUserDataRes, updateParticulartUserData, selectedParticularList, setOtherUserId, searchText, tracks, handleUserId, viewTrackDetail, closeNewMessage, openNewMessage, isShowMsgModal, handleAddNewUser, loading, setTrackIndex, selectedTrackIndex, showSmallDMView, closeProfileMedia, navigation, id, otherUserID, newUserAdded, generalListLoading, followerListLoading,selectedTab,selectedTrackIndexId, handleBlockUser }: any) => {
    const { isPlaylist} = useContext(AudioContextApi);
    const closeMsgModal = (newUser: any) => {
        closeNewMessage()
        handleAddNewUser(newUser)
        setTrackIndex(newUser.id)

    }
console.log(followerListLoading,"DM")
    return (
        <Box>
            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
               <Box style={{}} className="hide_in_desktop_menuicon">
                        <IconButton
                        style={{
                            color: "white",
                            padding: '5px'
                        }}
                        >
                        <MenuIcon className="menu-icon" style={{ width: '3rem', height: '2.5rem' }} onClick={handleMenu} />
                        </IconButton>
                        <IconMenu anchorEl= {anchorEl} handleClose={handleClose}/>
                        </Box>
                <Box style={{width: '80%,'}}>
                <Search onSearchChange={(value: any) => {
                    searchText(value.target.value)
                }} placeholder="Search DMs" style={{ width: "100%" }} />
                 </Box>
                <img src={Message_Icon_Blue} style={{ height: '52px', width: '52px', borderRadius: '50%' ,marginRight: '2px', marginLeft: '5px',cursor:"pointer"}}
                    onClick={() => openNewMessage()}
                />
            </Box>
            {loading ? (
                <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
                    <img src={nxzloader} style={{ width: "10%" }} />
                </Row>
            ) : (
                <>
                    <Box className="RepliesBox" style={{ display: "flex", flexDirection: "column" }}><Typography className="RepliesText"><span className="ReplyNoText">{tracks?.length}</span> DMs</Typography></Box>
                    <Box style={{ height: '100%',paddingBottom:'15px' }} className={isPlaylist?"CommentContainerScroll audio-inboxDMListContainer":"CommentContainerScroll inboxDMListContainer"}>
                        {newUserAddedState &&
                            <NewDMContact
                                media={tempTracks}
                                // type={track.attributes.track_upload_type} 
                                selected={false}
                                unread={tempTracks.attributes?.is_read}
                                onHandleUserId={handleUserId}
                                onHandleNotUserId={handleUserId}
                                onUserProfileClick={viewTrackDetail}
                                TrackIndex={0}
                                setTrackIndex={setTrackIndex}
                                selectedTrackIndex={selectedTrackIndex}

                            />}
                        {
                           tracks?.length > 0 ?
                           tracks.sort((a:any, b:any) => {
                               if (a.type === 'search' && b.type !== 'search') {
                                 return -1;
                               } else if (a.type !== 'search' && b.type === 'search') {
                                 return 1;
                               } else {
                                 return 0;
                               } }).map((track:any, index:any) => {
                                    return (
                                        <React.Fragment key={track.id} >
                                            <MediaDMCP
                                                media={track}
                                                // type={track.attributes.track_upload_type} 
                                                onClearNewAddedUser={onClearNewAddedUser}
                                                selected={false}
                                                unread={track.attributes?.is_read}
                                                onHandleUserId={handleUserId}
                                                onHandleNotUserId={handleUserId}
                                                onUserProfileClick={viewTrackDetail}
                                                TrackIndex={newUserAddedState ? 1 + index : index}
                                                setTrackIndex={setTrackIndex}
                                                selectedTrackIndex={selectedTrackIndex}
                                                keyId={[track.id, "" + track.attributes.receiver_id, "" + track.attributes.sender_id]}
                                            />
                                        </React.Fragment>
                                    )
                                })
                                :
                              !newUserAddedState &&
                                    <>
                                        <div style={{ height: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                            <div style={{ color: 'white', fontSize: '20px', fontWeight: 800 }}> No Message</div>
                                        </div>
                                    </>
                        }
                    </Box>
                </>
            )}
                <DMProfileDialog
                isOpen={showProfileDialog}
                id={id}
                navigation={navigation}
                otherUserId={otherUserID}
                newUserAdded={newUserAdded}
                closeMediaDialog={closeProfileMedia}
                particularUserDataRes={particularUserDataRes}
                updateParticulartUserData={updateParticulartUserData}
                selectedParticularList={selectedParticularList}
                setOtherUserId={setOtherUserId}
                handleBlockUser={handleBlockUser}
            />
            <DMChatDialog
                isOpen={showSmallDMView}
                closeMediaDialog={closeProfileMedia}
                navigation={navigation}
                id={id}
                otherUserId={otherUserID}
                newUserAdded={newUserAdded}
                selectedTab={selectedTab}
                selectedTrackIndexId={selectedTrackIndexId}

            />
            {isShowMsgModal &&
                <ListofNewChat showMsgModal={isShowMsgModal} closeMsgModal={closeMsgModal} closeModalDialog={closeNewMessage} />
            }
        </Box>
    );
    // Customizable Area End
}
// Customizable Area Start
export default DMList;
// Customizable Area End