// Customizable Area Start
import { Layout,Dropdown } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React from 'react';
import './downloadPage.css';
import { Hidden, Box } from '@material-ui/core';
import  MonthTrack  from './MonthTrack.web';
import CustomDownloadCarousel from './CustomDownloadCarousel.web';
import type { MenuProps } from 'antd';
import axios from "axios";
import DownloadController from './DownloadController';
import AsideLeft from 'components/src/CustomChart/PopUps/AsideLeft.web';
import HomeHeader from 'components/src/CustomChart/PopUps/HomeHeader.web';
import { Filter_black, nxzloader, triangle } from '../../dashboard/src/assets';
import CustomAudioPlayer from '../../AudioMusic/src/CustomAudioPlayer.web';
import { AudioContextApi } from '../../dashboard/src/AudioContext/AudioContext.web';

const urlConfig = require("../../../framework/src/config")

const baseUrl = urlConfig.baseURL;
const token: any = localStorage.getItem("token")

export default class DownloadPage extends DownloadController {
  handleMenuClick: MenuProps['onClick'] = (e) => {
    const apiToken: any = localStorage.getItem("token")
    this.setState({ filterValue:`${e.key.split(' ')[0]}`.toLocaleUpperCase()})
    axios.get(`${baseUrl}/user_download_history_filter?upload_type=${e.key.split(' ')[1]}&filter_value=${e.key.split(' ')[0]}`, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",

        token: apiToken
      }

    })
      .then((response: any) => {
        if (response?.data?.data) {
          this.setState({ currentTrack: response?.data?.data[0] })
        }
        this.setState({ filteredData: response.data.data ? response?.data?.data :[] })
        this.setState({ isFilter: true })
      })
  };

  render() {
    const { loading, data, currentTrack, filteredData, isFilter, playlistData, selectedPlayList, checkType, handleAudioVideo, playPlaylist,filterValue }: any = this.state;
    const { isPlaylist } = this.context;

    const creativeItems: MenuProps['items'] = [
      {
        key: '1',
        label: 'Today',
        children: [
          {
            key: 'today Beat',
            label: 'Beat',
          },
          {
            key: 'today Hook',
            label: 'Hook',
          },
          {
            key: 'today Verse',
            label: 'Verse',
          },
          {
            key: 'today Song',
            label: 'Song',
          },
        ],
      },
      {
        key: '2',
        label: 'This Week',
        children: [
          {
            key: 'this_week Beat',
            label: 'Beat',
          },
          {
            key: 'this_week Hook',
            label: 'Hook',
          },
          {
            key: 'this_week Verse',
            label: 'Verse',
          },
          {
            key: 'this_week Song',
            label: 'Song',
          },
        ],
      },
      {
        key: '3',
        label: 'This Month',

        children: [
          {
            key: 'this_month Beat',
            label: 'Beat',
          },
          {
            key: 'this_month Hook',
            label: 'Hook',
          },
          {
            key: 'this_month Verse',
            label: 'Verse',
          },
          {
            key: 'this_month Song',
            label: 'Song',
          },
        ],
      },
      {
        key: '4',
        label: 'This Year',
        children: [
          {
            key: 'this_year Beat',
            label: 'Beat',
          },
          {
            key: 'this_year Hook',
            label: 'Hook',
          },
          {
            key: 'this_year Verse',
            label: 'Verse',
          },
          {
            key: 'this_year Song',
            label: 'Song',
          },
        ],
      },
    ];
   

    const listenerItems: MenuProps['items'] = [
      {
        key: '1',
        label: 'Today',
        children: [
          {
            key: 'today Song',
            label: 'Song',
          }
        ]

      },
      {
        key: '2',
        label: 'This Week',
        children: [
          {
            key: 'this_week Song',
            label: 'Song',
          }
        ]
      },
      {
        key: '3',
        label: 'This Month',
        children: [
          {
            key: 'this_month Song',
            label: 'Song',
          }
        ]
      },
      {
        key: '4',
        label: 'This Year',
        children: [
          {
            key: 'this_year Song',
            label: 'Song',
          }
        ]
      },
    ];
    const items:any = localStorage.getItem("user_type")?.includes('creative') ? creativeItems : listenerItems;
    const menuProps = {
      items,
      onClick: this.handleMenuClick,
    };
    return (
      <>
        <div>
          <div className='downloadMain'>
            <Layout className='downloadLayout'>
              <Hidden smDown>
                <AsideLeft />
              </Hidden>
              {/* <Content className='download_content'>
                <HomeHeader />
                {loading ? (
                  <>
                    <Box className='download_lodder'>
                      <img src={nxzloader} style={{ width: "5%" }} />
                    </Box>
                  </>
                ) : (
                  <Box className={isPlaylist ? 'audioplayer_Contain' : 'download_Contain'}>
                  
                    {!Object.keys(data).includes('message') && Object.keys(data).length > 0 ?
                        <Box className='download-sub-height'>
                        {Object.keys(currentTrack).length > 0 && (

                          <CustomDownloadCarousel currentTrack={currentTrack} />
                        )}
                        <Box className='download_songs_lists'>
                          <Box style={{height:"100%"}}>
                            <Box className='downloadHead'>
                              <h3>Tracks Name</h3>
                              <h3>Type</h3>
                              <h3>Genre</h3>
                              <h3>Tempo</h3>
                              <h3>Key</h3>
                              <Box style={{ position: 'relative' }}>

                                <Dropdown menu={menuProps}>
                                  <div
                                    className="ant-dropdown-link filter_profile listener-home-filter"
                                    onClick={(e) => e.preventDefault()}  >
                                    <div style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "inherit"
                                    }}>
                                      <img src={Filter_black} style={{ height: "10px", width: "11px" }} alt="" />
                                      <p style={{ fontSize: "13px", fontWeight: "bold", margin: "auto", color: "black" }}>{filterValue}</p>
                                      </div>
                                      <img src={triangle} style={{ height: "10px", width: "11px" }} alt="" />
                                    </div>
                                </Dropdown>
                              </Box>
                            </Box>
                            <Box
                              className='month_track_contain'
                            >
                              <MonthTrack data={data} handleCurrentTrack={this.handleCurrentTrack} filteredData={filteredData} isFilter={isFilter} topPlayMusic={this.topPlayMusic} />
                            </Box>
                          </Box>
                        </Box>

                      </Box>
                      :
                      <p className='no-downloads'>You don't have download histories</p>


                    }
                  </Box>
                )}
              </Content> */}
            </Layout>
          </div>
        </div>
        {
          playPlaylist && (
            <div
              style={{
                position: "fixed",
                left: 0,
                bottom: 0,
                width: "100%",
              }}
            >
              <CustomAudioPlayer
                playlistData={playlistData}
                playList={selectedPlayList}
                setPlaylistData={(value: any) => this.setState({ playlistData: value })}
                checkType={checkType}
                handleAudioVideo={handleAudioVideo}
                setHandleAudioVideo={(value: any) => this.setState({ handleAudioVideo: value })}
              />
            </div>
          )
        }
      </>
    );
  }
}
DownloadPage.contextType = AudioContextApi;
// Customizable Area End