// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/common";
import axios from 'axios'
import { openNotification } from '../../../../components/src/Notification.web';
import { setStorageData , removeStorageData} from "../../../../framework/src/Utilities";

export const configJSON = require("../../../dashboard/src/config.js");
const baseUrl = configJSON.baseUrl;

export interface Props {
    navigation?: any;
    id?: string;
    setActiveStep: () => void;
    redirectSplit : (step: any) => void;
}
interface S {
    token: any;
    loading: any;
    scaned: any;
    recognization: any;
    fingerPrint: any;
    UploadFile: any;
    audioFile: any;
    videoFile: any;
    uploadType: any;
    trackFromCustomLibrary: any;
    validationText: any;
    playing: any;
    count: any;
}
interface SS {
    id: any;
}

export default class ScanProcessController extends BlockComponent<Props, S, SS> {
  intervalId: NodeJS.Timeout | null = null;
    constructor(props :Props){
      super(props);
      this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];
 
        this.state = {
            token: localStorage.getItem("token"),
            loading: false,
            scaned: false,
            recognization: 0,
            fingerPrint: 0,
            UploadFile: 0,
            audioFile: "",
            videoFile: "",
            uploadType: window.location.href.split('/')[4],
            trackFromCustomLibrary: false,
            validationText: "",
            playing: false,
            count: 0
        };
        this.togglePlay = this.togglePlay.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    recognizeAudio = async(audioFile: any) =>{
      this.setState({loading: true})
       const fmData: any = new FormData();
         fmData.append('file', audioFile);

       const header = {
           "Content-Type": configJSON.dashboarContentType,
           'token': this.state.token
         };
         const headers = {
           "Content-Type": "application/json",
           'token': this.state.token
         };

         await axios.post(`${ baseUrl + '/bx_block_audiomusic/check_recognition_state'}`,fmData,{ headers }

       )
       .then((response: any) => {        
       // After some condition or time, clear the interval
          if(response){
           this.handleRecognizeResponse(response, audioFile)
          }
         }).catch((err: any) => {
           this.setState({loading: false, audioFile: null, videoFile: null, count: 0})
           openNotification('Recognition failed', 'error')
         });

      
   }
    async componentDidMount() {
        super.componentDidMount();
        this.setState({trackFromCustomLibrary: false})
    }

   async componentDidUpdate(prevProps:any, prevState:any) {
      if (this.state.loading && !this.intervalId) {
        this.intervalId = setInterval(() => {
          this.setState((prevState: any) => ({
            count: prevState.count + 2,
          
          }));
          
        }, 200);
      }
      if(this.state.count ===100  && this.intervalId){
        clearInterval(this.intervalId);
        this.intervalId = null; 
      }
  
      if (!this.state.loading && this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null; 
      }
    }

    togglePlay() {
      const audio = document.getElementById('audioPlayer') as HTMLAudioElement;
      if (audio) {
        if (this.state.playing) {
          audio.pause();
        } else {
          audio.play();
          audio.onended = () => {
            this.setState({ playing: false });
          };
        }
        this.setState({ playing: !this.state.playing });
      }
    }

    backClick = () =>{
        HISTORY.push('/uploadTrack')
    }

    afterNextClick = () =>{
      if(this.state.scaned){
        if(this.state.trackFromCustomLibrary){
          this.props.redirectSplit(5);
        }else{
          this.setState({trackFromCustomLibrary: false})
          this.props.setActiveStep();
        }
        this.setState({ validationText: ''})
      }
      else{
        this.setState({ validationText: 'please select any file to upload'})
      }
    }

     uploadMp3 = (options: any, uploadType: any) =>{
        console.log(options, 'mp3 file')

        const {  file } = options;
        setStorageData('draft_file', file)
        if(uploadType == 'audio'){
          if(file.type.split("/")[0] == "audio"){
            this.setState({audioFile: file, validationText: ''})
            this.recognizeAudio(file)
          }else{
            this.setState({ validationText: 'Please Select audio file'})
          }
        }else if(uploadType == 'video'){
          if(file.type.split('/')[0] == 'video'){
            this.setState({videoFile: file, validationText: ''})
            this.recognizeAudio(file)
          }else{
            this.setState({ validationText: 'Please Select video file'})
          }
        }
      }



    handleRecognizeResponse = async(responseJson: any, file: any) =>{
       if(responseJson != null && responseJson?.data){
        if(responseJson?.data?.track){
          await setStorageData('uploaded_file_exists', responseJson?.data?.track?.data)
          await setStorageData('uploaded_trackId', responseJson?.data?.track?.data?.id)
          this.setState({trackFromCustomLibrary: true,loading:false})
        }else{
          await removeStorageData('uploaded_file_exists')
          this.setState({trackFromCustomLibrary: false,loading:false})
          await removeStorageData('uploaded_trackId')
        }
         this.setState({scaned: true, recognization: responseJson?.data?.recognition_percentage, fingerPrint: responseJson?.data?.fingerprint_percentage, UploadFile: 100,loading:false})
          await setStorageData('uploaded_file', file)
         if(responseJson?.data?.message == "I own, and have rights required to upload this content"){
           openNotification(responseJson?.data?.message, 'success')
         }
         this.setState({ 
          validationText: 'Successfully uploaded', 
        });
       }

    }


}
// Customizable Area End