// Customizable Area Start
import React from 'react'
import { Box, IconButton, Typography } from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ParticularUserController from './ParticularUserController';
import { createBrowserHistory } from "history";
import { deleteicon, follow, marknew, AddUserMsg } from '../../../dashboard/src/Inbox/assets';
import { readMessageMedia ,unreadMessage} from '../ChatUtils';
import HomeHeaderDropdown from 'components/src/CustomChart/PopUps/HomeHeaderDropdown.web';
import { defaultProfile } from '../../../dashboard/src/assets';
import DeleteConversation from '../DeleteConversation.web';

interface Props {
    title: string;
    onclick: () => void;
    image: string;
    newUserAddedState: any;
}


const ImageReceieve = ({ title, onclick, image, newUserAddedState }: Props) => {
    return (
        <Box className='AVICBtnBox'>
            <IconButton
                className='Btn'
                onClick={onclick}
                disabled={newUserAddedState}
            >
                {title == "Unfollow" || title == "Follow" ?
                    <input type="image" src={image} alt=""
                        style={{
                            cursor: "pointer", width: "40px",
                            height: "40px"
                        }}
                    />
                    :

                    <input type="image" src={image} alt="" disabled={newUserAddedState}
                        style={{
                            cursor: newUserAddedState ? "not-allowed" : "pointer", width: "40px",
                            height: "40px"
                        }}
                    />
                }
            </IconButton>
            <Typography className='BtnText'>{title}</Typography>
        </Box>
    )


}


export const ActionButton = (props: { title: string, onClick: () => void }) => {
    return (
        <Box className='AVICBtnBox'>
            <IconButton
                className='Btn'
                onClick={props.onClick}
            >
                <PlayArrowIcon />
            </IconButton>
            <Typography className='BtnText'>{props.title}</Typography>
        </Box>
    )
}




const dmCount = localStorage.getItem('messageCount') || 0;
let currentArtist: any;
let currentArtWorkClass:string="default-profile-mediaDMCP";
const history = createBrowserHistory()

export default class ParticularUser extends ParticularUserController {

    returnParticularArtist() {
        let logged_in_user_profile = localStorage.getItem('profile_img');
        if (logged_in_user_profile == "null") {
            logged_in_user_profile = defaultProfile
        }
        if (Object.keys(this.props.particularUserDataRes).length == 0) {
            currentArtist = logged_in_user_profile
            currentArtWorkClass="mediaDMCP-avatar"
        } else if (this.props.particularUserDataRes?.attributes?.artist_image) {
            currentArtist = this.props.particularUserDataRes?.attributes?.artist_image
            currentArtWorkClass="mediaDMCP-avatar"
        } else {
            currentArtist = defaultProfile
            currentArtWorkClass="default-profile-mediaDMCP"
        }
        return currentArtist
    }

    redirectProfile = () => {
        const user_type: any = localStorage.getItem("user_type")
        const user_id: any = localStorage.getItem("loggedIn_userId")
        let currentuserType: any;
        let currentUserId: any;

        

        if (Object.keys(this.props.particularUserDataRes).length == 0) {
            currentuserType = user_type
            currentUserId = user_id
        } else {
            currentuserType = this.props.particularUserDataRes?.attributes?.artist_role
            currentUserId = this.props.particularUserDataRes?.id
        }
        localStorage.removeItem("selectedTab")
      
        const ids: any = localStorage.getItem("user_id")
         localStorage.setItem("temp_id", ids)
         localStorage.removeItem("user_id")
         localStorage.setItem("user_id", currentUserId)
         localStorage.setItem("creatives_screen", "true")
         localStorage.setItem("current_type", currentuserType);

        if (currentuserType.includes("creative")) {
            history.push(`/creatives/beats?creative_id=${currentUserId}`)
            window.location.reload()
        } else if (currentuserType.includes("listener")) {
            history.push(`/listeners/activity?listener_id=${currentUserId}`)
            window.location.reload()
        } else {
            history.push(`/originals/beats?originals_id=${currentUserId}`)
            window.location.reload()
        }

    }

    readStatusMsg() {
        const { readStatus } = this.state;
        const currentUserID = localStorage.getItem("loggedIn_userId")
        const senderId = this.props.selectedParticularList[0]?.attributes?.sender_id;
        const receiverId = this.props.selectedParticularList[0]?.attributes?.receiver_id;
        if (readStatus) {
            readMessageMedia(currentUserID == receiverId ? senderId : receiverId);
        } else {
            unreadMessage(currentUserID == receiverId ? senderId : receiverId);
        }
    }

    render() {
        const { particularUserDataRes, newUserAddedState, otherUserID } = this.props;
        const isFollowing = particularUserDataRes?.attributes?.is_following;
        const followUserImg = isFollowing ? follow : AddUserMsg
        const logged_in_user_name = localStorage.getItem('user_name');
        const logged_in_user_type: any = localStorage.getItem('user_type');
        return (
            <div className="dm-dropdown">
                <div className="dropdown-border">
                    <HomeHeaderDropdown sizexl={24} dmActive={true} />
                </div>
                <Box className='particularUserWeb'>
                    <div className='self-profile-particularuser'>
                    <img
                        className={currentArtWorkClass}
                        onClick={this.redirectProfile}
                        src={
                            this.returnParticularArtist()
                        }
                    />
                    </div>
                    <Typography className='AVICMediaName' style={{ fontSize: "16px" }}>{particularUserDataRes?.attributes?.artist_name || logged_in_user_name}</Typography>
                    <Typography className='AVICMediaCreatorName' style={{ color: "#8f92a1" }}>{particularUserDataRes?.attributes?.artist_role || logged_in_user_type}</Typography>
                    {Object.keys(particularUserDataRes).length !== 0 ?
                        <Box className='AVICButtonsContainer'>


                            <ImageReceieve
                                title={isFollowing ? "Unfollow" : "Follow"}
                                onclick={() => {
                                    isFollowing ?
                                        this.doUnFollow() :
                                        this.doFollow();
                                }} image={followUserImg} newUserAddedState={newUserAddedState} />
                            <ImageReceieve title="Mark New" onclick={() => {
                                this.readStatusMsg()

                            }} image={marknew} newUserAddedState={newUserAddedState} />
                            <ImageReceieve title="Delete" onclick={() => { this.handleDeleteModalOpen(); }} image={deleteicon} newUserAddedState={newUserAddedState} />
                            <ImageReceieve 
                                title="Block"
                                onclick={() => { this.props.handleBlockUser(this.props.otherUserID, true); }}
                                image={deleteicon}
                                newUserAddedState={newUserAddedState}
                            />
                        </Box>
                        :
                        <>
                            <div style={{ marginTop: '10px' }}>
                                <span style={{ color: 'gray', fontSize: '24px', fontWeight: 900 }}>You Have <span style={{ color: 'blue', fontSize: '24px', fontWeight: 900 }}>{dmCount}</span></span>
                            </div>
                            <div>
                                <span style={{ color: 'gray', fontSize: '24px', fontWeight: 900 }}>New Messages</span>
                            </div>
                        </>
                    }
                </Box>
                <DeleteConversation isOpen={this.state.isOpen} handleDMClose={this.handleDMClose}
                    handleDelete={this.handleDMdelete}
                />
            </div>
        )
    }
}
// Customizable Area End